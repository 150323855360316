<template>
  <div class="create_preview">
    <div class="preview_box">
      <h5 :class="{ tip_h5_old: isOldCase }" class="tip_h5">
        {{
          tabKey === "3"
            ? `${userInfo.realName}${$t(
                "casesDetail.casesChildren.operationChildren.dblzl"
              )}`
            : $t("casesDetail.casesChildren.operationChildren.yxzljmx")
        }}
      </h5>
      <ul class="preview_box_ul">
        <template v-show="tabKey === '3'">
          <public-cases-user
            :isOldCase="isOldCase"
            :userInfo="userInfo"
            :caseInfo="caseInfo"
          />
          <PreviewDiagnosis
            :isOldCase="isOldCase"
            :commonTeeth="commonTeeth"
            :uploadIntervalList="uploadIntervalList"
            :veneerList="commonVeneerList"
            :crowdTeethList="crowdTeethList"
            :key="1"
            :productType="caseInfo.cureType"
            :casesData="casesData"
            :details="details"
            :isDark="true"
          />
        </template>
        <public-cases-pic
          :surfacePicList="surfacePicList"
          :xPicList="xPicList"
          :details="details"
          v-if="tabKey === '4'"
        />
      </ul>
    </div>
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import PublicCasesUser from "../../../publicCases/casesUser_copy";
import PublicCasesPic from "../../../publicCases/casesPic";
import { toCompleteCurePlan, doctorSubmitCurePlan } from "common/api/cases";
import { notifyMsg } from "common/js/util";
import { get, filter } from "lodash";
import { mapGetters } from "vuex";
import PreviewDiagnosis from "../../../../../components/treatment-table/preview-table-copy/index";
let lang = localStorage.getItem("user_lang") || "zh_CN";

export default {
  data() {
    return {
      tabKey: "",
      copyCases: null,
      surfacePicList: [
        "sideFacePicture",
        "frontFacePicture",
        "smilePicture",
        "upToothPicture",
        "anteriorOverjetPicture",
        "downToothPicture",
        "mouthRightPicture",
        "mouthFrontPicture",
        "mouthLeftPicture",
      ],
      xPicList: ["xrayCurvePicture", "xraySidePicture"],
      otherPic: "otherPicture",
      subParamsList: [
        {
          diagnosisForm: [],
        },
        {
          picParams: [],
        },
      ],
      isLoading: false,
    };
  },
  props: {
    selectedTabKey: {
      type: String,
      default: "",
    },
    details: {
      type: Object,
      default: () => ({}),
    },
    caseInfo: {
      type: Object,
      default: () => ({}),
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    teethKeyObj: {
      type: Object,
      default: () => ({}),
    },
    babyTeethKeyObj: {
      type: Object,
      default: () => ({}),
    },

    commonTeeth: {
      type: Array,
    },
    commonVeneerList: {
      type: Array,
    },
    isDark: {
      required: false,
    },
    // uploadIntervalList: {
    //   type: Array,
    // },
    crowdTeethList: {
      type: Array,
    },
    isOldCase: {
      type: Boolean,
      default: false,
    },
    curePlanDetail: {
      required: false
    }
  },
  computed: {
    ...mapGetters({
      commonCaseCureDetail: "getCommonCaseCureDetail", //治疗表详情
      commonDiagnosisDetail: "getCommonDiagnosisDetail", // 治疗表资料详情
    }),
    uploadIntervalList() {
      return filter(
        this.details.toothInfoList,
        (item) => item.type === "5"
      );
    },
    queryPort() {
      return "";
    },
    needRdt() {
      sessionStorage.setItem("needRTD", get(this.curePlanDetail, "helpFlag") === "1");
      return get(this.curePlanDetail, "helpFlag") === "1";
    },
    gap() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_i",
            },
            {
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "gap",
          pList: [
            {
              showTeeth: true,
              type: "gap",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "gap",
              key: "teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "gap",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "gap",
              key: "teeth",
              teethType: 3,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval",
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    adult() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem",
            },
            {
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two",
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification",
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification",
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "adult",
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "adult",
              key: "teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "adult",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "adult",
              key: "teeth",
              teethType: 3,
            },
          ],
        },

        {
          fIndex: 8,
          title: this.$t("cases.createPreview.jzgx"),
          pList: [
            {
              filed: "rightSagittalRelation",
              inquireKey: "right_sagittal_relation",
              secondLevel: this.$t("cases.createDiagnosis.you"),
            },
            {
              filed: "rightSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "rightSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "rightSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "rightSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },

            {
              filed: "leftSagittalRelation",
              inquireKey: "left_sagittal_relation",
              secondLevel: this.$t("cases.createPreview.zuo"),
            },
            {
              filed: "leftSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "leftSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "leftSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "leftSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createPreview.fg"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createPreview.fh"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open",
              sublevel: true,
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createPreview.yd"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info",
            },

            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: this.$t("cases.createPreview.jzqyfh"),
              sublevel: true,
            },
            {
              filed: "correctFrontOpposite",
              inquireKeys: ["correct_front_opposite"],
              parentText: this.$t("cases.createPreview.jzqydrh"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createPreview.hsh"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              filed: "upjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.she"),
            },
            {
              filed: "downjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.xhe"),
            },
            {
              filed: "gapCorrect",
              inquireKey: "gap_correct",
              key: "reserve",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "15.1 " + this.$t("cases.createDiagnosis.jcyjsx"),
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "15.2 " + this.$t("cases.createDiagnosis.qtlczc"),
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "15.3 " + this.$t("cases.createDiagnosis.jcyjjzfs"),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 "
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml36 mt12"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              filed: "toothExtraction",
              key: "teeth",
              teethType: 4,
              type: "adult",
              superiorText: this.$t("cases.createDiagnosis.by"),
              inquireKey: "tooth_extraction_options",
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.sfphz"),
          pList: [
            {
              filed: "growNailFlag",
              inquireKey: "grow_nail_flag",
            },
            {
              filed: "upGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.se"),
            },
            {
              filed: "downGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.xe"),
            },
            {
              filed: "growNailPurpose",
              inquireKey: "grow_nail_purpose",
              superiorText:
                "16.2 " + this.$t("cases.createDiagnosis.nzrzzkdzy"),
            },
            {
              filed: "growNailFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createPreview.hgj"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    youthList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_b",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_b",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two",
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification",
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification",
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.blxg"),
          pList: [
            {
              filed: "badHabits",
              inquireKey: "bad_habits",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "badHabitsNote",
              type: "note",
            },
          ],
        },
        // {
        //   fIndex: 5,
        //   title: this.$t('cases.createDiagnosis.jzzk'),
        //   pList: [
        //     {
        //       filed: 'dentition',
        //       inquireKey: 'dentition'
        //     },
        //   ]
        // },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt3"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw_b",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt4"),
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt5"),
          key: "teeth",
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              key: "teeth",
              type: "youth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "youth",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              key: "teeth",
              type: "youth",
              teethType: 3,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.txt7"),
          pList: [
            {
              filed: "sagittalRelation",
              inquireKey: "sagittal_relation",
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "left",
              describeInputKey: "leftSagittalRelationMolarNote",
              sublevel: true,
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "right",
              describeInputKey: "rightSagittalRelationMolarNote",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.hxgx"),
          pList: [
            {
              filed: "upHorizontalRelationship",
              inquireKey: "up_horizontal_relationship",
              secondLevel: this.$t("common.common.up"),
            },
            {
              filed: "upHorizontalRelationshipNote",
              inquireKey: "up_horizontal_expansion",
              describeInputKey: "upHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downHorizontalRelationship",
              inquireKey: "down_horizontal_relationship",
              secondLevel: this.$t("common.common.down"),
            },
            {
              filed: "downHorizontalRelationshipNote",
              inquireKey: "down_horizontal_expansion",
              describeInputKey: "downHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.txt8"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.txt9"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoExtrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "extend_front_tooth",
                },
              ],
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.qyfh"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info_b",
            },
            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: "",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createDiagnosis.txt11"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createDiagnosis.txt13"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createDiagnosis.txt14"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr",
              ],
              superiorText: "17.1 " + this.$t("cases.createDiagnosis.jcyjsx"),
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore",
              ],
              superiorText: "17.2 " + this.$t("cases.createDiagnosis.qtlczc"),
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "17.3 " + this.$t("cases.createDiagnosis.jcyjjzfs"),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",

              parentName: "body_up",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",

              parentName: "body_up",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
            },
            {
              filed: "crowdCorrectSide",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              inquireKey: "choose_option_b",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              key: "adjoin",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.by"),
          key: "teeth",
          teethType: 4,
          type: "youth",
          pList: [
            {
              filed: "toothExtraction",
              key: "teeth",
              type: "youth",
              inquireKey: "tooth_extraction_options",
              teethType: 4,
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.exgjsfczyc"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 20,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 21,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    babyList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_c",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_b",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              inquireKey: "tooth_problem_two_c",
              superiorText: this.$t("cases.createPreview.ycwt"),
            },
            {
              filed: "jawProblemTwo",
              inquireKey: "jaw_problem_two",
              superiorText: this.$t("cases.createPreview.egwt"),
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        // {
        //   fIndex: 3,
        //   title: this.$t('cases.createDiagnosis.txt2'),
        //   pList: [
        //     {
        //       filed: 'sagittalMaxillomandibularClassification',
        //       superiorText: this.$t('cases.createDiagnosis.gxfl'),
        //       inquireKey: 'sagittal_maxillomandibular_classification'
        //     },
        //     {
        //       filed: 'clinicInfo',
        //       inquireKey: 'clinic_info',
        //       superiorText: this.$t('cases.createDiagnosis.chlx'),
        //     },
        //     {
        //       tit: this.$t('cases.createDiagnosis.txt17'),
        //       filed: 'clinicOtherInfo',
        //       type: 'note'
        //     }
        //   ]
        // },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.blxg"),
          pList: [
            {
              filed: "badHabits",
              inquireKey: "bad_habits",
            },
            {
              filed: "badHabitsNote",
              tit: this.$t("cases.createDiagnosis.txt17"),
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.txt3"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw_b",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt4"),
          key: "teeth",
          type: "children",
          pList: [
            {
              showTeeth: true,
              type: "children",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          key: "teeth",
          type: "children",
          pList: [
            {
              type: "children",
              key: "teeth",
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          type: "children",
          pList: [
            {
              type: "children",
              key: "teeth",
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              teethType: 3,
            },
          ],
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.txt7"),
          pList: [
            {
              filed: "sagittalRelation",
              inquireKey: "sagittal_relation",
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "left",
              describeInputKey: "leftSagittalRelationMolarNote",
              sublevel: true,
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "right",
              describeInputKey: "rightSagittalRelationMolarNote",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.hxgx"),
          pList: [
            {
              filed: "upHorizontalRelationship",
              inquireKey: "up_horizontal_relationship",
              secondLevel: this.$t("common.common.up"),
            },
            {
              filed: "upHorizontalRelationshipNote",
              inquireKey: "up_horizontal_expansion",
              describeInputKey: "upHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downHorizontalRelationship",
              inquireKey: "down_horizontal_relationship",
              secondLevel: this.$t("common.common.down"),
            },
            {
              filed: "downHorizontalRelationshipNote",
              inquireKey: "down_horizontal_expansion",
              describeInputKey: "downHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.txt8"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.txt9"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoExtrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "extend_front_tooth",
                },
              ],
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.qyfh"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info_b",
            },
            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: "",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.txt11"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct_b",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createDiagnosis.txt13"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval",
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              // superiorText: this.$t('cases.createDiagnosis.jcyjjzfs'),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymry"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymry"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },
            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
            },
            {
              filed: "crowdCorrectSide",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              inquireKey: "choose_option_b",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              key: "adjoin",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createDiagnosis.sfxyfzmy"),
          pList: [
            {
              filed: "eruptedCompensate",
              inquireKey: "erupted_compensate",
            },
            {
              filed: "eruptedCompensateNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    veneerList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem",
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem",
            },
            {
              filed: "toothOtherProblem",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two",
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification",
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification",
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw",
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true,
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "adult",
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "adult",
              key: "teeth",
              teethType: 2,
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "adult",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "adult",
              key: "teeth",
              teethType: 3,
            },
          ],
        },

        {
          fIndex: 8,
          title: this.$t("cases.createPreview.jzgx"),
          pList: [
            {
              filed: "rightSagittalRelation",
              inquireKey: "right_sagittal_relation",
              secondLevel: this.$t("cases.createDiagnosis.you"),
            },
            {
              filed: "rightSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "rightSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "rightSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "rightSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },

            {
              filed: "leftSagittalRelation",
              inquireKey: "left_sagittal_relation",
              secondLevel: this.$t("cases.createPreview.zuo"),
            },
            {
              filed: "leftSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "leftSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind",
                },
                {
                  key: "leftSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump",
                },
                {
                  key: "leftSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction",
                },
              ],
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createPreview.fg"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info",
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createPreview.fh"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info",
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open",
              sublevel: true,
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front",
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back",
                },
              ],
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note",
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createPreview.yd"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info",
            },

            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two",
              ],
              parentText: this.$t("cases.createPreview.jzqyfh"),
              sublevel: true,
            },
            {
              filed: "correctFrontOpposite",
              inquireKeys: ["correct_front_opposite"],
              parentText: this.$t("cases.createPreview.jzqydrh"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createPreview.hsh"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info",
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct",
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line",
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true,
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true,
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              filed: "upjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.she"),
            },
            {
              filed: "downjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.xhe"),
            },
            {
              filed: "gapCorrect",
              inquireKey: "gap_correct",
              key: "reserve",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
            },
          ],
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr",
              ],
              superiorText: "15.1 " + this.$t("cases.createDiagnosis.jcyjsx"),
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore",
              ],
              superiorText: "15.2 " + this.$t("cases.createDiagnosis.qtlczc"),
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30",
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              superiorText: "15.3 " + this.$t("cases.createDiagnosis.jcyjjzfs"),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8",
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar",
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8",
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : "",
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up",
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12",
            },
            {
              filed: "toothExtraction",
              key: "teeth",
              teethType: 4,
              type: "adult",
              superiorText: this.$t("cases.createDiagnosis.by"),
              inquireKey: "tooth_extraction_options",
            },
          ],
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.sfphz"),
          pList: [
            {
              filed: "growNailFlag",
              inquireKey: "grow_nail_flag",
            },
            {
              filed: "upGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.se"),
            },
            {
              filed: "downGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.xe"),
            },
            {
              filed: "growNailPurpose",
              inquireKey: "grow_nail_purpose",
              superiorText:
                "16.2 " + this.$t("cases.createDiagnosis.nzrzzkdzy"),
            },
            {
              filed: "growNailFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 17,
          title: this.$t("cases.createPreview.hgj"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.xfsj"),
        },
        {
          fIndex: 19.1,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.sxyw"),
          pList: [
            {
              veneer: "veneer",
              showVeneer: true,
              type: "veneer",
              teethType: 10,
            },
          ],
        },
        {
          fIndex: 19.2,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.yysx"),
          pList: [
            {
              filed: "originalTeethColor",
              inquireKey: "original_teeth_color",
            },
            {
              filed: "originalTeethColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19.3,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.mbys"),
          pList: [
            {
              filed: "targetColor",
              inquireKey: "target_color",
            },
            {
              filed: "targetColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 19.4,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.ymwl"),
          pList: [
            {
              filed: "surfaceTexture",
              inquireKey: "surface_texture",
            },
          ],
        },
        {
          fIndex: 19.5,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.sjyq"),
          pList: [
            {
              filed: "designRequestOne",
              inquireKey: "design_request_one",
            },
            {
              filed: "designRequestTwo",
              inquireKey: "design_request_two",
            },
          ],
        },
        {
          fIndex: 20,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    braceList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.exgjsfczyc"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.yzsfzc"),
          pList: [
            {
              filed: "periodontalDisease",
              inquireKey: "periodontal_disease",
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "periodontalDiseaseNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
    yysdList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.ycxxqy"),
          pList: [
            {
              showYysd: true,
              teethType: 1,
            },
          ],
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.ycxxjy"),
          pList: [
            {
              showYysd: true,
              teethType: 11,
            },
          ],
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.lczk"),
          pList: [
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info_f",
            },
            {
              filed: "clinicOtherInfo",
              type: "note",
            },
            {
              filed: "occlusalRelationship",
              superiorText: this.$t("cases.createDiagnosis.yhgx"),
              inquireKey: "occlusal_relationship",
            },
            {
              filed: "adjacentGap",
              superiorText: this.$t("cases.createDiagnosis.ljx"),
              inquireKey: "adjacent_gap",
            },
            {
              filed: "toothProblemThree",
              superiorText: this.$t("cases.createDiagnosis.ycwt"),
              inquireKey: "tooth_problem_three",
            },
            {
              filed: "toothOtherProblemThree",
              type: "note",
            },
          ],
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.sxyw"),
          pList: [
            {
              showYysd: true,
              childKey: true,
            },
            {
              filed: "desiredToothPosition",
              type: "note",
            },
          ],
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.yysx"),
          pList: [
            {
              filed: "originalTeethColor",
              inquireKey: "original_teeth_color_f",
            },
            {
              filed: "colorSystem",
              inquireKeys: ["ivoclar", "vita"],
              describeInputKey: "colorNumber",
              isShowMM: false,
            },
            {
              filed: "originalTeethColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.mbyase"),
          pList: [
            {
              filed: "targetColor",
              inquireKey: "target_color",
            },
            {
              filed: "targetColorNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.mblx"),
          pList: [
            {
              filed: "targetType",
              inquireKey: "target_type",
            },
            {
              filed: "targetTypeNote",
              type: "note",
            },
          ],
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.mbtmd"),
          pList: [
            {
              filed: "targetTransparency",
              inquireKey: "target_transparency",
            },
          ],
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.cllx"),
          pList: [
            {
              filed: "materialType",
              inquireKey: "material_type",
            },
          ],
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.sfby"),
          pList: [
            {
              filed: "whetherToPrepareTeeth",
              inquireKey: "whether_to_prepare_teeth",
              type: "yysd",
              teethType: 14,
              showYysd: true,
            },
          ],
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.tmhdyq"),
          pList: [
            {
              filed: "veneerThicknessRequirements",
              value: true,
            },
          ],
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.ymwl"),
          pList: [
            {
              filed: "surfaceTexture",
              inquireKey: "surface_texture",
            },
          ],
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.sjyq"),
          pList: [
            {
              filed: "designRequestOne",
              inquireKey: "design_request_one_f",
            },
            {
              filed: "designRequestTwo",
              inquireKey: "design_request_two_f",
            },
          ],
        },
        {
          fIndex: 14,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note",
            },
          ],
        },
      ];
    },
  },
  methods: {
    get,
    getDiaOption() {
      this.casesData = {
        A: this.adult,
        B: this.youthList,
        C: this.babyList,
        D: this.braceList,
        E: this.veneerList,
        F: this.yysdList,
        I: this.gap
      }[this.caseInfo.cureType];
    },
  },
  watch: {
    selectedTabKey(val) {
      this.tabKey = val;
    },
  },
  created() {
    this.tabKey = this.selectedTabKey;
    this.getDiaOption();
  },
  beforeDestroy() {
    sessionStorage.removeItem('needRTD')
  },
  components: {
    FullLoading,
    PublicCasesUser,
    PublicCasesPic,
    PreviewDiagnosis,
  },
};
</script>

<style scoped lang="scss">
.create_preview {
  padding: 0.2rem 1.17rem 0.7rem 0;
  font-size: 0.16rem;
  .preview_tip {
    .p_tip_ul {
      width: 80%;
      margin-top: 0.45rem;
      .p_tip_li {
        margin-bottom: 0.05rem;
        .p_tip_l_h {
          display: flex;
          align-items: center;
          position: relative;
          padding-left: 0.19rem;
        }
        .l_h_sp {
          color: $main_theme_color;
          font-size: 0.3rem;
          position: absolute;
          left: -0.06rem;
          top: 50%;
          transform: translateY(-50%);
        }
        .p_tip_l_box {
          border-left: 0.01rem solid $main_theme_color;
          padding: 0.17rem 0 0.29rem 0.19rem;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          box-sizing: border-box;
          span {
            margin-bottom: 0.1rem;
            padding-bottom: 0.02rem;
            border-bottom: 0.01rem solid $main_theme_color;
            color: $main_theme_color;
            cursor: pointer;
            margin-right: 0.29rem;
          }
        }
        &:last-child {
          .p_tip_l_box {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .tip_h5 {
    display: flex;
    align-items: center;
    font-size: 0.2rem;
    .tip_icon {
      display: inline-block;
      width: 0.15rem;
      height: 0.15rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-right: 0.13rem;
    }
    .yellow_tip_icon {
      background-image: url("../../../../../common/imgs/3d/accomplish.png");
    }
    .blue_tip_icon {
      background-image: url("../../../../../common/imgs/3d/blue_accomplish.png");
    }
    .purple_tip_icon {
      background-image: url("../../../../../common/imgs/3d/purple_accomplish.png");
    }
  }
  .tip_h5_old {
    color: $main_theme_color_333;
  }
  .preview_box {
    .preview_box_ul {
      margin-top: 0.38rem;
    }
  }
  .preview_foo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .preview_foo_p {
      color: #fff;
      background-color: $main_theme_color;
      width: 3rem;
      height: 0.6rem;
      line-height: 0.6rem;
      border-radius: 1rem;
      margin-bottom: 0.16rem;
      text-align: center;
      font-size: 0.2rem;
      cursor: pointer;
    }
    .preview_foo_txt {
      color: #999999;
      font-size: 0.16rem;
    }
  }
}
</style>
